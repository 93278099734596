.post-header {
  width: 100%;
  padding: 0 18px;
  gap: 10px;
  margin: 12px 0 22px 0;

  .more-options {
    button {
      background-color: transparent;
    }
    .options-container{
      padding: 0;
      width: 280px;
      .options-col{
        width: 100%;
      }
      .option-row{
        background-color: transparent;
        padding: 10px 15px;
        gap: 15px;
        .option-icon{
          width: 22px;
          height: 22px;
        }
        .op-text {
          width: 100%;
          cursor: pointer;
          .report-icon{
            svg{
              path{
                stroke: var(--just-blacker);
              }
            }
          }
          h4 {
            font-size: 14px;
            font-family: "Lato", sans-serif;
          }

          p {
            font-family: "Lato", sans-serif;
            padding: 2px 0;
            font-size: 12px;
            color: var(--greyish-blue-2);
          }
        }
      }
    }
  }
}