.start-record-btn{
    background-color: transparent;
}
.audio-timer h5 span{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: var(--black);
}
.recording-details{
    gap: 5px;
}