.bold {
  font-weight: bold;
}

.b-500 {
  font-weight: 500;
}

.description-text {
  color: var(--greyish-blue-2);
  font-size: 14px;
}