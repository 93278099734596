.options-menu {

    position: relative;
  
    .options-container {
      position: absolute;
      right: 0;
      top: 30px;
      border-radius: 6px;
      background-color: white;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      padding: 1rem;
      z-index: 3;
      overflow: hidden;
  
      .options-col {
        max-width: 400px;
        width: max-content;
      }
  
      .option-row {
        width: 100%;
        cursor: pointer;
        background-color: white;
        padding: 5px;
        display: flex;
        gap: 10px;
  
        &:hover {
          background: var(--greyish-3);
  
          &.option-row-disabled {
            background: unset;
          }
        }
  
        &.option-row-disabled {
          cursor: default;
        }
      }
    }
  
  }