.update-post-popup {
  width: min(100%, 600px);
  padding: 22px 20px;

  .modal-body {
    width: 100%;
    gap: 20px;

    .upp-header {
      width: 100%;
      margin-bottom: 10px;

      .upp-close-icon-btn {
        position: absolute;
        width: 22px;
        height: 22px;
        padding: 0;
        background: none;
        border-radius: var(--br-circle);
        right: 20px;
      }
    }

    .upp-text-area {
      width: 100%;

      textarea {
        width: 100%;
        background-color: var(--greyish-3);
        border-radius: 8px;
        padding: 20px;

        &::placeholder {
          color: var(--greyish-blue-2);
        }
      }
    }

    .upp-post-update-btn {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      font-size: 16px;

      &:disabled {
        background-color: var(--greyish-3);
        color: var(--greyish-blue-2);
      }
    }
  }
}