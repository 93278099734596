:root {
  --white: #ffffff;
  --just-blacker: #0d0d0d;
  --black: #292B3E;
  --red: #d0021b;
  --orange: #ff6100;
  --dark: #0f1721;
  --grey: #ACB3BA;
  --grey-5: #E0E0E0;
  --grey-light: #F2F6FB;
  --greyish-3: #F7F7F7;
  --bright-gray: #EEEEEE;
  --greyish-3-opacity-50: rgba(247, 247, 247, .5);
  --greyish-2: #C7C7C7;
  --greyish-blue-2: #89939F;
  --pastel-green: #D0F0EA;
  --pastel-red: #f4c1c1;
  --just-green: #01A385;
  --just-green-2: #01A385;
  --border-greyish-blue-2: #89939F1A;
  --light-green: #01A385;
  --light-green-filter: invert(60%) sepia(84%) saturate(415%) hue-rotate(47deg) brightness(91%) contrast(91%);
  --greyish-blue-2-filter: invert(62%) sepia(32%) saturate(129%) hue-rotate(173deg) brightness(88%) contrast(90%);
  --dark-green: #02A383;
  --blue: #578DF5;
  --arya-coin-gold: #FFC32F;
  --arya-coin-gold-gradient: linear-gradient(0deg, #FFD323 0%, #FFC222 56%, #FFBD22 100%);;
  --green-gradient: linear-gradient(136.05deg, #01A385 12.16%, #02A383 86.37%);
  --br-inputs: 8px;
  --br-circle: 50%;
  --box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.05);
  --credit: #40B266;
  --debit: #F12020;
  --begonia: #FF6F6F;
  --venetian-red: #E12525;
  --change-red: #facfd4;
  --change-red-text: #ff6363;
  //--change-red: #FF9FA7;
  //--change-red: #FDEAEC;
  --change-green: #D0F0EA;
  --bluish: #F3F7FF;
  --just-blue-2: #578DF5;
  --just-blue-2-transperent: #578DF51A;
  --strong-grey: #ACB3BA;
  --light-grey: #ACB3BA;
  --just-whiter: #FFFFFF;
  --locked-post-gradient: linear-gradient(0deg, #FFFFFF 4.63%, rgba(255, 255, 255, 0) 218.3%);
  --lighter-green: #D0F0EA;
}