.audio-display-wrapper {
  height: 45px;
  padding: 0 7px;
  gap: 8px;


  .record-btn {
    height: 100%;
    aspect-ratio: 1;
    background-color: var(--greyish-3);
    flex-shrink: 0;
    border-radius: 8px;

    button {

      svg {
        height: 18px;
        width: 18px;

        path {
          fill: var(--light-green);
        }
      }

    }
  }

  .audio-c {
    height: 100%;
    border-radius: 8px;
    margin: 5px 0;
    background-color: var(--greyish-3);;
    padding: 13px 23px;
    gap: 20px;
  }

  .audio-speed {
    margin: 0 5px;
    cursor: pointer;
    user-select: none;
  }
}