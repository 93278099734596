
.progress-fd {
  width: 100%;
  position: relative;
  gap: 10px;

  .p-grey {
    width: 100%;
    position: relative;
    overflow: hidden;

    .p-progress-point {
      flex: 1;
      height: 20px;
      z-index: 1;
      justify-content: flex-end;

      .p-line {
        flex: 1;
        height: 4px;
        background-color: var(--greyish-2);
      }

      .p-circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: var(--greyish-2);
      }


      &.active-progress-point, &.progress-passed-point {
        .p-line {
          background-color: #01A385;
        }

        .p-circle {
          background-color: #01A385;
        }

        &.red {
          * {
            background-color: #F12323;
          }
        }
      }


      &.StopLoss {
        flex-direction: row-reverse;
      }

      &.EntryValue {
        width: fit-content;
        flex: unset;
      }
    }


  }

  .p-texts {
    width: 100%;
    color: var(--greyish-blue-2);

    h5 {
      width: 15px;
      font-weight: 400;
      text-align: center;
    }

    & .fst {
      margin-left: 0;
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}