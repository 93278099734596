.arya-coins-popup {
  width: min(100%, 600px);
  border-radius: 10px;


  .modal-body {
    gap: 35px;
    padding: 10px 20px 30px;

    .acp-header {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 500;
    }

    .acp-content {
      width: min(100%, 550px);
      gap: 20px;

      .acp-loading {
        width: 100%;
        min-height: 445px;
      }


      .acp-packages-wrapper {
        gap: 40px;

        .acp-packages-grid {
          width: 100%;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 20px;

          .acp-package {
            width: 100%;
            height: 100px;
            padding: 0;
            aspect-ratio: 20/15;
            border-radius: 8px;
            gap: 0;
            background-color: var(--greyish-3);

            &.acp-selected-package {
              outline: 2px solid var(--just-blue-2);
              background-color: var(--bluish);
            }

            .acp-coins-amount {
            }

            .acp-usd-value {
              font-size: 18px;
            }
          }
        }

        .acp-complete-purchase-btn {
          width: min(245px, 100%);
          height: 45px;
        }
      }
    }
  }
}