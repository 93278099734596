.contactlist{
    min-height: 62px;
    margin: 2px 0;
    border-radius: 10px;
    background: transparent;
    padding: 0 12px;

    .contact-details{
        margin-left: 14px;
        .contact-name{
            text-align: left;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .contact-designation{
            font-size: 14px;
            max-width: 165px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--greyish-blue-2);
        }
    }
    .new-message-indicator{
        width: 8px;
        height: 8px;
        background-color: var(--blue);
        border-radius: 50%;
        margin-left: auto;
    }
}
.contactlist:hover{
    background-color: var(--greyish-3);
    cursor: pointer;
}
.selected{
    background-color: var(--greyish-3);
}