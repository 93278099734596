.last-update {
  width: 100%;
  padding: 0 16px;
  gap: 15px;

  &:last-child {
    margin-bottom: 16px;
  }

  .lu-content-wrapper {
    gap: 20px;

    .lu-content {
      gap: 7px;
      font-weight: 400;

      .lu-time-ago {
        color: var(--just-green);
        font-weight: 500;
      }
    }
  }
}