.chat-settings-wrapper{
    position: absolute;
    background-color: white;
    width: auto;
    height: 100%;
    z-index: 2;
    left: 0;
    .setting-sidebar{
        width: 272px;
        height: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 17px 14px;
        .sidebar-header{
            height: 40px;
            margin-bottom: 6px;
            .return-chat{
                display: flex;
                justify-content: center;
                align-items: center;
                background: transparent;
                margin: 0 12px  0 0;
            }
        }
        .setting-actions{
            .selected{
                background:var(--just-green-2);
                color: white;
                .button-wrapper{
                    svg{
                        path{
                            fill: white;
                        }
                    }
                }
                #about,#ignoreChat,#unmute,#leave{
                    svg{
                        .stroke{
                            fill: none;
                            stroke: white;
                        }
                        line{
                            stroke: white;
                        }
                    }
                }
            }

            button{
                width: 255px;
                height: 47px;
                border-radius: 6px;
                background: transparent;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 15px;
                .button-wrapper{
                    display: flex;
                    flex-direction: row;
                    svg{
                        margin-right: 20px;
                    }
                }
            }
        } 
        
    }
    .setting-main{
        width: 100%;
        height: 100%;
    }
}

