.chat-container {
  z-index: 10;
  position: fixed;
  overflow: hidden;
  height: auto;
  width: auto;
  bottom: 0;
  right: 1.875em;
  background-color: #fff;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  .new-chat-indicator {
    border-radius: 50%;
    background-color: var(--begonia);
    color: var(--white);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chat-close {
    height: 70px;
    width: 317px;
    padding-inline: 17px;
    background: transparent;
    position: relative;
  }

  .chat-open {
    position: relative;
    padding: 17px 20px;
    width: 718px;
    height: 506px;
    gap: 22px;

    .new-request-counter {
      background-color: var(--red);
      color: var(--white);
      border-radius: 50%;
      min-width: 20px;
      height: auto;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .chat-contactlist {
      width: 272px;
      height: 100%;

      .contactlist-header {
        margin-bottom: 17px;
        min-height: 40px;
        margin-right: 10px;
        width: calc(100% - 10px);

        .return-discussion {
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          margin: 0 12px 0 0;
        }

        .chatbox-actions {
          position: relative;
          display: flex;
          align-items: center;
          gap: 20px;

          button {
            position: relative;

            svg {
              width: 18px;
              height: 18px;
              path {
                stroke: var(--just-green);
              }
            }

            .new-request-indicator {
              height: 15px;
              background-color: var(--red);
              border-radius: 50%;
              position: absolute;
              bottom: 5px;
              right: 5px;
              transform: translate(50%, 50%);
              color: white;
              font-size: 10px;
              //padding: 0 4px;
              aspect-ratio: 1;
              text-align: center;
            }
          }

          .more-options {
            margin: 0 5px;
            background-color: transparent;


          }

          .options-box {
            position: absolute;
            background: white;
            width: 170px;
            height: 70px;
            top: 35px;
            left: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            z-index: 50;

            button {
              background: transparent;
              text-align: left;
              padding: 0 15px;
              color: var(--greyish-blue-2);
              width: 100%;
              height: 100%;
              font-size: 12px;
              display: flex;
              align-items: center;

            }

            button:hover {
              background: var(--greyish-3);
            }
          }
        }
      }

      .chat-filter {
        gap: 6px;
        margin-bottom: 15px;
        margin-right: 10px;
        width: calc(100% - 10px);

        .cht-fltr-btn {
          flex: 1;
          height: 34px;
          border-radius: 8px;
          color: var(--greyish-blue-2);
          background: var(--greyish-3);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .fltr-btn-selected {
          background: var(--just-green-2);
          color: white;
        }

        .dropdown-box {
          position: relative;

          .dropdown-filter {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .contactlist-wrapper {
        position: relative;
        overflow-y: scroll;
        height: 100%;
        width: 258px;
        padding-right: 4px;

        .popup-container {
          position: absolute;
        }

        .contactlist-placeholder {
          color: var(--greyish-2);
          padding: 0 15%;
          text-align: center;
        }
      }
    }

    .chatview {
      width: 100%;
      height: 100%;

      .chatview-header {
        margin-bottom: 17px;
        min-height: 40px;

        .contact-info-settings {
          gap: 10px;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
          }
        }

        .chatview-placeholder {
          margin: 8px 0;
        }

        .chatview-actions {
          .action-button {
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .expand {
            svg {
              path {
                fill: #c7c7c7;
              }
            }
          }
        }
      }

      .chatview-screen {
        height: 100%;
        background-color: #fbfbfb;
        //background-color: var(--greyish-3);
        //background-color: #f7fbff;
        margin-top: 2px;
        border-radius: 8px;

        .chatview-wrapper {
          position: relative;
          height: 100%;
          width: 100%;
        }

        .chatview-empty {
          height: 100%;
          margin-inline: auto;

          .empty-header {
            font-size: 12px;
            line-height: 18px;
            margin: 11px 0 6px 0;
          }

        }
      }
    }
  }
}

.chat-label {
  margin-inline: 10px;
}