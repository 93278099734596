.create-chat-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 18px;
  gap: 10px;

  .popup-container {
    position: absolute;
  }

  .user-list {
    width: 100%;
    position: relative;
    align-items: flex-start;
    overflow-y: auto;

    .user-box {
      font-size: 14px;
      width: 100%;
      padding: 10px 14px;
      border-bottom: 1px solid var(--bright-gray);
      .chat-user-name {
        gap: 10px;
      }

      .invite-button {
        border-radius: 6px;
        background-color: var(--just-green);
        color: white;
        padding: 5px 10px;
      }

      .invite-button:hover {
        background-color: var(--just-green-2);
      }
    }
  }

  .start-chat {
    width: 170px;
    height: 40px;
    background-color: var(--just-green);
    border-radius: 6px;
    color: white;
    //position: absolute;
    bottom: 25px;
    left: 130px;
    flex-shrink: 0;
  }
}

.newchat-search {
  width: 100%;
  background-color: transparent;
  border-bottom: 1px solid var(--greyish-blue-2);
  font-size: 12px;
  color: var(--greyish-blue-2);
  padding: 10px 0 5px;
  gap: 12px;
  overflow-x: auto;
  flex-shrink: 0;

  .member-name {
    position: relative;
    background-color: white;
    white-space: nowrap;
    padding: 8px 6px;
    border-radius: 4px;

    .remove-member {
      display: none;
      position: absolute;
      background: white;
      border: 1px solid var(--greyish-2);
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: -10px;
      right: -10px;

      svg {
        rect {
          fill: var(--greyish-blue-2);
        }
      }
    }
  }

  .member-name:hover {
    .remove-member {
      display: flex;
    }
  }

  input {
    flex: content;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border: none;
    outline: none;
    color: var(--greyish-blue-2);
    font-size: 12px;
  }
}