@import "src/assets/styles/mixins";

.page-container {
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  padding-top: 56px;
  overflow-x: hidden;
  overflow-y: auto;

  &.unauthorized-page-container {
    background-color: white;
    padding-top: 0;
  }

  .page-wrapper {
    width: 100%;
    max-width: 1113px;
    padding: 20px 4rem;
    align-items: flex-start;
    justify-content: space-between;

    @include tablet {
      padding: 20px 20px 80px;
    }

    @include mobile {
      padding: 10px 10px 80px;
    }

    .left-page-col {
      width: 65%;
      margin-bottom: 100px;

      @include tablet {
        width: 100%;
      }
    }

    .right-page-col {
      width: 34%;
      margin-bottom: 100px;

      @include tablet {
        display: none;
      }
    }

    .center-page-col {
      width: 100%;
      max-width: 940px;
    }
  }
}

.card {
  border-radius: 6px;
  background-color: white;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.02);
}

.gg-btn, .grey-btn, .gw-btn, .gb-btn, .wg-btn, .bw-btn, .outlined-btn {
  height: 35px;
  border-radius: 8px;
  padding: 0 30px;
  flex-shrink: 0;
}

.gg-btn { // gray background, green text
  color: var(--just-green);
  background-color: var(--greyish-3);

  &:hover {
    color: var(--just-green);
  }
}

.grey-btn { // gray background, green text
  color: var(--greyish-blue-2);
  background-color: var(--greyish-3);
}

.gg-icon-btn {
  height: 35px;
  width: 35px;
  border-radius: var(--br-circle);
  background-color: var(--greyish-3);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path{
      stroke: var(--greyish-blue-2);
    }
    width: 15px;
  }
}

.gw-btn { // green background, white text
  color: white;
  background: var(--dark-green);
}

.bw-btn { // green background, white text
  height: 35px;
  color: white;
  background: var(--just-blue-2);
  border-radius: 50px;
  padding: 0 30px;
  flex-shrink: 0;
}

.gb-btn { // grey background, black text
  color: var(--greyish-blue-2);
  background-color: var(--greyish-3);
}

.wg-btn { // white background, grey text
  color: var(--greyish-blue-2);
  background: white;
}

.bw-btn { // blue background, white text
  color: white;
  background: var(--blue);
}

.green-outlined-btn { // white background, grey text
  height: 35px;
  color: var(--just-green-2);
  border: 1px solid var(--just-green-2);
  background: white;
  border-radius: 50px;
  padding: 0 30px;
}

.outlined-btn {
  border: 1px solid var(--greyish-blue-2);
  color: var(--greyish-blue-2);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-btn {
  display: flex;
  background: none;
  padding: 0;
  margin: 0;
}

.link-btn {
  color: var(--just-blue-2);
  font-weight: 500;
  background: none;
  padding: 0;
  margin: 0;
}

.compact-btn {
  width: unset;
  height: unset;
  padding: 0 10px;
}



.arya-btn {
  height: 60px;
  border-radius: var(--br-inputs);
  font-size: 14px;
  font-weight: 600;
}


.popup-container {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  font-family: 'Poppins', serif;
}

.popup-container-main {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 150;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  font-family: 'Poppins', serif;
  background-color: #00000033;
}

.popup-container-second {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: 20;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  font-family: 'Poppins', serif;
  background-color: #00000033;
}

.textarea-input {
  width: 100%;
}

.text-input-container {
  position: relative;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .text-input-actions {
    right: 0;
    content: " ";
    position: absolute;
    //display: flex;

    * {
      margin-right: 10px;
    }

    .text-input-max-length {
      color: var(--greyish-blue-2);
      font-weight: 400;
    }

    .text-input-info {
      width: 18px;
      height: 18px;
    }
  }
}

.input-error {
  margin-top: 5px;
  color: var(--red);
  font-size: 12px;
}

.clickable {
  cursor: pointer;
}

.select-search-container {
  --select-search-background: var(--greyish-3);
  --select-search-border: none;
  --select-search-text: var(--greyish-blue-2);
  margin-bottom: 20px;
  width: 100%;

  .select-search-input {
    &::placeholder {
      color: var(--greyish-blue-2);
      font-weight: 500;
    }
  }

  .select-search-select {
    position: absolute;
    z-index: 2;
    top: 47px !important;
    right: 0;
    left: 0;
    border-radius: 3px;
    display: none;
  }
}

.dropdown-input-option {
  margin: 10px 0;
  overflow-x: hidden;
  height: 50px;
  padding: 0 10px;

  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100px;
    margin-right: 10px;
  }

  .dropdown-input-option-label {
    width: calc(100% - 60px);
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.image-input-container {
  width: 100%;
  margin-bottom: 20px;

  .img-container {
    width: 100%;
    max-width: 88px;
    aspect-ratio: 1;
    position: relative;
    background-color: var(--greyish-2);
    border-radius: 50%;

    .selected-image {
      width: 100%;
      height: 100%;
      background-color: var(--greyish-2);
      border-radius: 50%;
      border: none;
      object-fit: cover;
    }

    .upload-file-btn {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 35px;
      height: 35px;
    }

    .description-text {
      width: 100%;
      text-align: center;
      position: absolute;
      color: white;
      font-size: 10px;
    }
  }
}

.toggle-input-container {
  input {
    width: unset;
  }

  .toggle-input-label {
    font-size: 16px;
    font-weight: 500;
    margin-right: 20px;
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: calc(100% - 4px);
    aspect-ratio: 1;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    left: 0;
    top: 0;
    transform: translate(2px, 2px);
  }

  input:checked + .slider {
    background-color: #34C759;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #34C759;
  }

  input:checked + .slider:before {
    -webkit-transform: translate(calc(100%), 13%);
    -ms-transform: translate(calc(100%), 13%);
    transform: translate(calc(100%), 13%);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

input[type="radio"] {
  width: 15px !important;
  height: 15px !important;
  padding: 0 !important;
  appearance: none;
  background-color: var(--greyish-2);
  margin: 0;
  font: inherit;
  color: currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;

  &::before {
    content: " ";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--light-green);
  }

  &:checked::before {
    transform: scale(1);
  }
}


// Scrollbar
* {
  &::-webkit-scrollbar {
    height: 5px;
    width: 7px;
    border-radius: 50px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--greyish-2);
    border-radius: 50px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--greyish-blue-2);
    border-radius: 50px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}


.tabs {
  width: 100%;

  .tabs-list {
    height: 40px;
    gap: 10px;
    display: flex;
    border-bottom: 1px solid var(--greyish-2);

    .tab {
      min-width: 100px;
      color: var(--greyish-blue-2);
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 14px;
      cursor: pointer;
      background: transparent;

      @include tablet {
        padding: 10px;
        font-size: 14px;
      }

      &:focus-visible {
        outline: none;
      }

      &.tab-selected {
        min-width: 100px;
        color: var(--just-green);
        border-bottom: 3.5px solid var(--just-green);
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
      }
    }
  }

}


.tooltip {
  width: 100%;
  position: relative;

  &:hover .tooltiptext {
    visibility: visible;
  }
}

.tooltiptext {
  visibility: hidden;
  width: 100%;
  min-width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -100%);
}

button {
  &:disabled {
    cursor: default;
  }
}

.arya-coin-text {
  color: var(--arya-coin-gold);
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--greyish-blue-2);
}


.show-original-text-btn {
  color: var(--greyish-blue-2);
  font-size: 12px;
  font-weight: 400;
}