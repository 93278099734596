h1 {
  font-size: 4rem;
  font-family: 'Poppins', sans-serif;
}

h2 {
  font-size: 26px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

h4 {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

h6 {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

p {
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  white-space: break-spaces;
}

small {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  white-space: break-spaces;
}

b {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  white-space: break-spaces;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:visited {
    text-decoration: none;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

select {
  resize: none;
  background: none;
  outline: none;
  border: none;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}