@import './colors.scss';
@import './tags.scss';
@import './mixins';
@import './styles.scss';
@import './fonts.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


html,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  //font-size: 100%;
  vertical-align: baseline;

}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  scroll-behavior: smooth;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

textarea {
  outline: none;
  resize: none;
  border: none;

  &:-webkit-autofill {

    &,
    &:hover,
    &:focus {
      border: none;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 16px;
  vertical-align: baseline;
  background-color: var(--ar-grey);
  position: relative;
  height: 100%;
  width: 100%;
  font-family: 'Poppins', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;

  @include tablet {
    -webkit-tap-highlight-color: transparent;
  }
}


button {
  font-family: 'Poppins', serif;
}

::placeholder {
  font-family: 'Poppins', serif;
}

input {
  font-family: 'Poppins', serif;
}

textarea {
  font-family: 'Poppins', serif;
  font-size: 14px;
  font-weight: 400;
}

main {
  position: relative;
  width: 100vw;
  height: 100vh;
  font-family: 'Poppins', serif;
  background-color: var(--greyish-3);
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::placeholder {
  color: #ffffff;
  font-size: 13px;
}

img {
  object-fit: contain;
}

input {
  outline: none !important;
}

.flex {
  display: flex;
}

.fdc {
  flex-direction: column;
}

.fdcr {
  flex-direction: column-reverse;
}

.fdr {
  flex-direction: row;
}

.fdrr {
  flex-direction: row-reverse;
}

.jcc {
  justify-content: center;
}

.jcfs {
  justify-content: flex-start;
}

.jcfe {
  justify-content: flex-end;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.jcse {
  justify-content: space-evenly;
}

.jsfe {
  justify-self: flex-end;
}

.aic {
  align-items: center;
}

.aifs {
  align-items: flex-start;
}

.aife {
  align-items: flex-end;
}

.ais {
  align-items: stretch;
}

.aib {
  align-items: baseline;
}

.asfe {
  align-self: flex-end;
}

.asfs {
  align-self: flex-start;
}

.fww {
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

.wb-100 {
  width: 100%;
}

.hb-100 {
  height: 100%;
}

.bld {
  font-weight: 600;
}

.rgl {
  font-weight: 400;
}

.right {
  right: 0;
}

.left {
  left: 0;
}

.transparent {
  background-color: transparent;
}

.loading {
  display: flex;
  width: 30px;
  height: 30px;
  border: 2px solid #2fd5ab;
  border-radius: var(--br-circle);
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: relative;
  z-index: 1;
}

.loading-main-button {
  margin: 5px;
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: var(--br-circle);
  border-top-color: #2fd5ab;
  animation: spin 0.5s ease-in-out infinite;
  -webkit-animation: spin 0.5s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  z-index: 1;
}

.loading-button-overlay {
  width: 100%;
  height: 100%;
}

.loading-button {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid #2fd5ab;
  border-radius: var(--br-circle);
  border-top-color: white;
  animation: spin 0.5s ease-in-out infinite;
  -webkit-animation: spin 0.5s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: relative;
  z-index: 1;
}

.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #2fd5ab;
  border-radius: var(--br-circle);
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
}