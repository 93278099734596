.invite-wrapper{
    width: 100%;
    height: 100%;
    gap: 48px;
    .invite-actions{
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 18px;
        margin-bottom: 35px;
        .invite-accept{
            height: 45px;
            width: 169px;
            border-radius: 6px;
            color: white;
            background: var(--just-green);
            font-size: 12px;
        }
        .invite-ignore{
            background: transparent;
            height: 16px;
            width: 132px;
            border-radius: 0;
            font-size: 12px;
            color: var(--greyish-blue-2);
        }
        .invite-ignore:hover{
            text-decoration: underline;
        }
    }
}