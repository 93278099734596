@import "src/assets/styles/mixins";

.financial-data-popup {
  width: min(100%, 1500px);
  height: min(100%, 800px);
  padding: 20px;
  font-family: 'Poppins', sans-serif;

  .modal-body {
    width: 100%;
    height: 100%;

    .fdp-body {
      width: 100%;
      height: 100%;
      gap: 20px;

      .fdp-header {
        width: 100%;
        gap: 30px;

        @include tablet {
          flex-direction: column;
        }

        .fdp-header-section {
          gap: 20px;

          @include tablet {
            flex-direction: column;
          }

          .fs-sell {
            gap: 5px;
            grid-template-columns: auto auto;

            .fs-action-point {
              justify-content: flex-start;
              flex-direction: row;
              gap: 10px;
            }
          }

          .progress-fd {
            padding-right: 40px;
            max-width: 50%;

            @include tablet {
              max-width: unset;
              padding-right: unset;
            }
          }

          .post-header {
            margin: 0;
          }
        }
      }

      .tr-chart {
        flex: 1;
        width: 100%;
        border-radius: var(--br-inputs);
        overflow: hidden;
        flex-shrink: 0;
      }
    }
  }

}


.fdp-asset {
  padding: 20px;
  background-color: var(--greyish-3);
  border-radius: 8px;
  gap: 20px;

  @include tablet {
    width: 100%;
  }

  .fdp-asset-img {
    width: 25px;
    border-radius: var(--br-circle);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .fdp-asset-info {
    gap: 10px;
  }
}