.check-box-container {
  width: 20px;
  height: 20px;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .check-box-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .check-mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .check-box-container:hover input ~ .check-mark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .check-box-container input:checked ~ .check-mark {
    background-color: var(--blue);
  }
  
  /* Create the check-mark/indicator (hidden when not checked) */
  .check-mark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the check-mark when checked */
  .check-box-container input:checked ~ .check-mark:after {
    display: block;
  }
  
  /* Style the check-mark/indicator */
  .check-box-container .check-mark:after {
    left: 8px;
    top: 5px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }