.single-notification{
    gap: 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .notification-picture-container{
        width: 42px;
        min-width: 42px;
        position: relative;
        svg{
            width: 22px;
            height: 22px;
            position: absolute;
            bottom: 0;
            right: -6px;
        }
    }
    .notification-left,.notification-right{
        gap: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .notification-right{
        margin-right: 10px;
    }
    .notification-text{
        font-size: 12px;
        font-weight: 400;
        &.nothandled{
          color: red;  
        }
        .notification-timestamp{
            color: var(--greyish-2);
            margin-left: 5px;
            white-space: pre;
        }
        .notification-comment{
            display: inline-block;
            color: var(--greyish-blue-2);
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: bottom;
        }
    }
    
    .new-post-indicator{
        min-width: 8px;
        height: 8px;
        background-color: var(--blue);
        border-radius: 50%;
        margin-left: auto;
    }
}

.single-notification:hover{
    background-color: var(--greyish-3-opacity-50);
    cursor: pointer;
    border-radius: 6px;
}