.edit-group-wrapper{
    padding: 20px 18px;
    width: 100%;
    height: 100%;
    gap: 15px;
    background-color: var(--greyish-3);
    .edit-group-avatar{
        width: 100%;
        .edit-avatar-button{
            background: transparent;
            margin-left: 20px;
            color: var(--greyish-blue-2);
        }
        .edit-avatar-button:hover{
            text-decoration: underline;
        }
    }
    .empty{
        position: relative;
        background-color: var(--just-green);
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
            path{
                fill: white;
            }
        }
    }
    .group-name,.member-search{
        width: 100%;
        ::placeholder{
            color: var(--greyish-blue-2);
        }
        input{
            width: 100%;
            height: 35px;
            border-radius: 6px;
            border: none;
            padding: 10px 18px;
        }
    }
    .member-search{
        margin-bottom: 20px;
    }
    .members-list{
        padding:10px;
        overflow-y: auto;
        width: 100%;
        max-height: 180px;
        .user-box{
            font-size: 14px;
            width: 100%;
            padding: 0 14px;
            .member-name{
                margin-left: 14px;
                font-size: 14px;
                width: 200px;
                white-space: nowrap;
                overflow:hidden;
                text-overflow: ellipsis;
            }
            .custom-width{
                width: 135px;
            }
            .admin-stamp{
                width: 65px;
                white-space:nowrap;
                color: var(--greyish-blue-2);
            }
           .member-options{
                position: relative;
                padding: 15px;

                .options-icon{
                    background-color: transparent;
                }
                .options-box{
                     background-color: white;
                     position: absolute;
                     top: 0;
                     right: 20px;
                     z-index: 100;
                     border-radius: 6px;
                     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                     width: 150px;
                     button{
                        background-color: transparent;
                        font-size: 12px;
                        padding: 10px 15px;
                        color: var(--greyish-blue-2);
                        width: 100%;
                        text-align: left;
                     }
                     button:hover{
                        background-color: var(--greyish-3);
                     }
                }
           }
        }
    }
    .member-count{
        margin-bottom: 15px;
        font-size: 12px;
    }
    .save-group-edit{
        padding: 0 15px;
        width: auto;
        height: 45px;
        border-radius: 6px;
        color: white;
        background-color: var(--just-green);
        font-size: 12px;
    }
}
.group-Avatar{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
}