
.si-form {
  width: 100%;
  border-radius: 8px;
  background-color: var(--greyish-3);
  overflow: hidden;
  height: 40px;

  .amp-search-input {
    width: calc(100% - 40px);
    height: 100%;
    position: relative;
    padding-right: 20px;

    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;

      &:not(:placeholder-shown) {
        padding: 0 20px;
      }
    }

    ::placeholder {
      color: var(--grey);
    }
  }

  .amp-search-btn {
    width: 40px;
    height: 100%;
    margin-right: 0;
  }
}