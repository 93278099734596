
.chat-input-wrapper {
  position: relative;
  width: 100%;

  .input-buttons {
    margin: 14px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .show-attach {
      margin: 0 9px;
      background: transparent;
      display: flex;
      align-items: center;

      svg {
        width: 35px;
        height: 35px;
      }
    }
    
    .audio-attachment {
      position: relative;
      .remove-file {
        border-radius: 8px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 12px;
          height: 12px;

          path {
            stroke: var(--greyish-blue-2);
          }
        }
      }
    }

    .element-wrapper {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      background-color: white;
      align-items: center;
      padding: 2px 5px 0 5px;
      width: 100%;

      .images-display {
        width: 100%;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 5px 0 5px;

        .image-card {
          position: relative;
          border-radius: var(--br-inputs);
          background-color: white;
          cursor: pointer;
          width: auto;
          height: auto;

          .pic-preview {
            object-fit: cover;
            width: 50px;
            height: 50px;
            border-radius: var(--br-inputs);
          }

          .remove-image {
            border-radius: 50px;
            position: absolute;
            bottom: 38px;
            left: 38px;
          }
        }
      }

      .input-wrapper {
        background-color: var(--white);
        padding: 3px 0;
        .mentions-input {
          flex: 1;
          border: none;
          max-height: 100px;
          font-size: 12px;
        }

        .mentions-input::placeholder {
          opacity: 1;
          color: gray;
        }

        .show-emoji {
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 5px;
          path {
            fill: var(--just-green-2);
          }
        }
      }
    }

    .send-message {
      margin: 0 9px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;

      svg {
        path {
          fill: var(--just-green-2);
        }
      }
    }

    .edit-actions {
      display: flex;

      button {
        padding: 5px;
        width: 30px;
        height: 30px;
        margin: 5px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      .close-edit {
        background-color: #d11a2a;

        svg {
          path {
            stroke: white;
          }
        }
      }

    }
  }

  .attach-buttons {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 8px;
    position: absolute;
    bottom: 45px;
    left: 12px;

    button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      margin: 3px 0;
      font-size: 10px;
      font-weight: 400;
      color: var(--greyish-blue-2);

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .emoji-picker {
    position: absolute;
    bottom: 50px;
    left: 70px
  }
}