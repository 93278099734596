.confirmation-popup {
  width: 450px;
  padding: 20px;
  border-radius: 8px;

  .cp-header, .cp-message, .cp-confirm-btn {
    margin-bottom: 20px;
  }

  .cp-header {
    width: 100%;

    p {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .cp-close-container {
      width: 22px;
      height: 22px;
      background: var(--greyish-3);
      border-radius: var(--br-circle);

      svg {
        width: 7px;
        height: 7px;

        path {
          stroke: black;
          stroke-width: 2.5;
        }
      }
    }
  }

  .cp-message {
    text-align: center;
  }
}