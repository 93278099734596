.fs-sell {
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(70px, max-content));

  .fs-action-point {
    min-width: fit-content;
    gap: 5px;

    h5 {
      color: #ACB3BA;
      text-transform: uppercase;
      font-weight: 400;

      span {
        color: black;
      }
    }
  }
}