@import "src/assets/styles/mixins";

.buy-arya-coins-form {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .payment-element {
    width: 100%;
    min-height: 220px;
  }

  .bacf-footer {
    width: 100%;
    gap: 25px;

    .bacf-powered-stripe-img {
      max-height: 30px;
      max-width: calc(100% - 120px);
    }

    .bacf-agree-to-agreement, .bacf-total {
      font-weight: 500;
    }

    .bacf-user-agreement {
      color: var(--just-blue-2);
      margin-left: 5px;
    }

    .bacf-available-coins-container {
      background-color: var(--greyish-3);
      padding: 20px;
      gap: 20px;
      border-radius: 8px;

      .bacf-arya-coins {
        color: var(--arya-coin-gold);
      }
    }

    .bacf-coins-after {
      font-weight: 400;
      color: var(--greyish-blue-2);
      gap: 10px;
    }

    img {
      max-width: min(100%, 350px);
    }

    .bacf-success-message {
      gap: 20px;
    }

    .bacf-order-summary {
      gap: 20px;

      .bacf-order-summary-value {
        border-top: 1px solid var(--greyish-2);
        padding-top: 20px;

      }
    }

  }

  .bacf-purchase-btn {
    width: 250px;
    height: 43px;
    margin-top: 15px;

    @include mobile {
      margin-top: 0;
    }
  }
}
