@import "src/assets/styles/mixins";

.modal-with-image {
  transform: translateY(calc(-50% + 30px));


  .modal-body {
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .mwi-header {
    width: 100%;
    height: 75px;
    position: relative;

    &.mwi-without-img-header {
      height: 20px;
    }

    @include tablet {
      height: 50px;
    }

    .mwi-image {
      position: absolute;
      width: 150px;
      height: 150px;
      object-fit: cover;
      object-position: center;
      //top: -20px;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 7px solid white;
      border-radius: var(--br-circle);

      @include tablet {
        width: 120px;
        height: 120px;
        //top: -10px;
      }
    }

    .mwi-close-btn {
      right: 20px;
    }

    .mwi-back-btn {
      left: 20px;
    }

    .mwi-close-btn, .mwi-back-btn {
      top: 20px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 22px;
      }
    }
  }
}