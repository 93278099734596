.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  //opacity: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  z-index: 150;
}

.modal-content {
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  max-width: 90%;
  max-height: 95%;
  border-radius: 10px;
}


.modal-body {
  //min-height: 200px;
  height: 100%;
  width: 100%;
}

.modal-header {
  width: 100%;
}