.awards-popup {
  width: min(100%, 500px);
  padding: 20px;

  .ap-content {
    text-align: center;
    gap: 20px;

    p, h4 {
      max-width: 300px;
    }

    .ap-img {
      width: 100px;
      aspect-ratio: 1;
    }

    .ap-awarded-by {
      width: 100%;
      max-width: 400px;
      gap: 10px;
      padding: 10px 5px;
      background-color: var(--greyish-3);
      border-radius: 10px;

      .ap-awarded-by-list {
        max-height: 150px;
        gap: 10px;
        overflow-y: auto;

        .ap-awarded-by-list-item {
          width: 100%;
          padding: 0 10px;
          text-align: start;
        }

        .ap-li-user-name {
          margin-left: 10px;
        }
      }
    }
  }
}