@import 'src/assets/styles/mixins';

.global-search-form {
  flex-grow: 1;
  min-width: 270px;
  height: 35px;
  border-radius: 5px;
  background-color: #F7F7F7;
  margin: 0 25px;
  position: relative;
  flex-shrink: 0;

  &:focus-within {
    .s-debounced-input {
      background-color: white;
      z-index: 2;
      border-radius: 4px;
      //border-bottom: 1px var(--grey-light) solid;
      border: 2px solid var(--just-blue-2);

      input {
        background-color: white;
      }
    }

    .s-autocomplete-dropdown {
      display: flex !important;
      z-index: 2;
    }

    .s-overlay {
      display: flex;
    }
  }

  .s-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000033;
    z-index: 1;
  }

  .s-autocomplete-dropdown {
    display: none;
    width: 100%;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    position: absolute;
    background-color: white;
    border-radius: 0 0 8px 8px;
    box-shadow: var(--box-shadow);
    gap: 23px;
    padding: 17px 20px;
    z-index: 1;

    &.empty {
      padding: 10px;
      color: var(--greyish-blue-2);
    }

    .s-autocomplete-list {
      gap: 12px;

      .s-autocomplete-dropdown-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 7px;

        img {
          width: 28px;
          height: 28px;
          border: 1px solid var(--greyish-3);
          margin: 0;
        }
      }

      .s-autocomplete-dropdown-section {
        .s-autocomplete-dropdown-loading {
          height: 25px;
          width: 25px;
        }
      }
    }
  }

  .s-debounced-input {
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    border: none;
    outline: none;
    color: black;
    font-size: 12px;
    font-weight: 500;
    @include tablet {
      width: 100%;
    }
  }
}