@import "src/assets/styles/mixins";

.mobile-nav-menu {
  width: 100%;
  max-width: 1440px;
  height: 56px;
  padding: 0 2rem;
  box-shadow: var(--box-shadow);
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 3;
  display: none !important;

  @include tablet {
    display: flex !important;
  }
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.nav-dropdown-wrapper, .notification-dropdown-wrapper {
  position: relative;
  display: flex;
}

.nav-dropdown {
  width: 400px;
  position: absolute;
  background: var(--white);
  top: 50px;
  right: 0;
  border-radius: 8px;
  padding: 18px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);

  .balance-widget {
    background-color: var(--greyish-3);
    padding: 0 20px;
    height: 55px;
    color: var(--greyish-blue-2);
    font-weight: 500;
    size: 14px;
    border-radius: 8px;
    margin-bottom: 10px;

    .wallet-text {
      font-weight: 600;
    }

    .coin-value {
      color: var(--arya-coin-gold);
      margin: 0 2px 0 4px;
      font-weight: 500;
    }

    .fiat-value {
      color: var(--just-blacker);
    }
  }

  .dropdown-links {
    .dropdown-link {
      height: 40px;
      width: 100%;
      display: flex;
      text-align: left;
      font-size: 14px;
      align-items: center;
      color: var(--greyish-blue-2);

      svg {
        width: 70px;
        max-height: 20px;
      }
    }

    .dropdown-link:hover {
      background-color: var(--greyish-3);
    }
  }
}