@import "src/assets/styles/mixins";

.fs-data {
  width: 100%;
  padding: 10px 18px;
  background: #F7F7F7;
  gap: 40px;

  .fs-left {
    flex: 1;
    flex-shrink: 0;
    gap: 20px;

    @include tablet {
      width: 100%;
    }

  }

  .fs-instrument {
    width: 100%;
    gap: 10px;
    //padding: 10px 20px;

    .fs-in-left {
      flex: 1;
      gap: 35px;

      @include tablet {
        width: 100%;
        justify-content: space-between;
        gap: 15px;
      }


      .fs-image-wrapper {
        gap: 10px;

        .fs-icon {
          width: 25px;
          border-radius: var(--br-circle);

          @include mobile {
            width: 30px;
            height: 30px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

      }
    }

    .fs-in-infos {
      gap: 10px;

      h5 {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .fs-actions {
      gap: 10px;

      .expand-btn {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

  }

  .fs-expandable {
    width: 100%;
    max-width: 700px;
    gap: 20px;
    padding: 0 15px;

    .fs-view-chart-btn {
      color: var(--black);
      padding: 5px 10px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}