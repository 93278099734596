.mentions-container {
    background-color: white;
    max-height: 200px;
    height: max-content;
    border: 1px solid var(--greyish-3);
    overflow-y: auto;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    li{
        width: 100%;
    }
    .ml-list-item {
      width: 100%;
      height: 50px;
      padding: 5px;
      background-color: white;
      gap: 10px;
      border-bottom: 1px solid var(--greyish-3);
      text-align: left;
    }
    .focused{
        background-color: var(--greyish-3);
    }
}
.mentions-mention {
    position: relative;
    z-index: 1;
    color: var(--just-blue-2);
    text-shadow: 1px 1px 1px white, 1px 1px 1px white, 1px 1px 1px white,
      1px -1px 1px white;
    pointer-events: none;
}
.mentions-input{
    font-size: 14px;
    width: 100%;
    overflow-wrap: break-all;
    word-wrap: break-all;

    -ms-word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-all;
}