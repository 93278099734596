@import '../assets/styles/mixins';

.nv-main {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: white;
  box-shadow: var(--box-shadow);

  .navbar-main {
    width: 100%;
    height: 56px;
    background-color: white;
    // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.157);
    padding: 0 4rem;
    max-width: 1113px;
    margin: auto;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #F7F7F7 inset !important;
    }

    input:-webkit-autofill {
      -webkit-text-fill-color: black !important;
      font-size: 16px;
    }

    .search {
      width: 500px;
      height: 100%;

      .navbar-logo {
        max-height: 25px;
        user-select: none;
        cursor: pointer;
      }
    }

    .m-right {
      width: 60%;
      gap: 20px;
    }

    .menu {
      width: 40%;
      gap: 20px;

      .menu-icon {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 18px;
        }

        .unread-notification-counter {
          /* circle shape, size and position */
          position: absolute;
          right: -0.7em;
          top: -0.7em;
          min-width: 1.6em; /* or width, explained below. */
          height: 1.6em;
          border-radius: 0.8em; /* or 50%, explained below. */
          border: 0.05em solid white;
          background-color: var(--begonia);
          padding: 4px;
          /* number size and position */
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.8em;
          color: white;
        }
      }

      .menu-icon:hover {
        svg {
          path {
            //stroke: var(--light-green) !important;
          }
        }
      }
    }

    .profile {
      width: unset;


      .p-infos {
        padding: 0 15px;
        width: unset;

        h3 {
          font-size: 14px;
          text-align: left;
          font-weight: 600;

        }

        p {
          font-size: 10px;
          font-weight: 600;
          color: #F2C94C;
        }
      }
    }
  }
}

.mobile-nav-left {
  width: calc(100% - 40px);

  .mn-logo-link {
    img {
      height: 25px;
    }
  }

  form {
    width: unset;
    min-width: unset;
    flex: 1 1;

    .f-input {
      min-width: unset;
    }
  }
}