.message-wrapper{
    width: 100%;
    overflow:auto;
    display: flex;
    flex: 1 1 1px;
    padding: 10px 0;
    overflow-anchor: none;
    #anchor{
        overflow-anchor: auto;
        height: 1px;
    }
}

.loading-main-button{
    padding: 10px;
}
.empty-message{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: var(--greyish-blue-2);
}
.date-header{
    color: var(--greyish-2);
    font-size: 12px;
    margin: 5px 0;
}
.generated-message{
    font-size: 12px;
    color: var(--greyish-2);
    margin: 10px 0;
    max-width: 300px;
    text-align: center;
}