@import "src/assets/styles/mixins";

.footer-menu {
  width: 100%;
  height: 70px;
  box-shadow: var(--box-shadow);
  background-color: white;
  position: fixed;
  bottom: 0;
  z-index: 3;
  display: none !important;

  @include tablet {
    display: flex !important;
  }
}

.footer-menu-item {
  width: 70px;
  height: 40px;
  margin: 5px;

  .fmi-label {
    font-size: 10px;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  &.footer-menu-item-active {
    color: var(--light-green);

    svg {
      * {
        stroke: var(--light-green);
      }
    }
  }
}