@import "/src/assets/styles/mixins";

.idea {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 20px;
  background-color: white;
  position: relative;


  @include tablet {
    padding: 0 12px;
  }

  .author {
    width: 100%;

    .avatar {
      width: 43px;
      height: 43px;
      border-radius: var(--br-circle);
      position: relative;
      margin-right: 20px;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: var(--br-circle);
      }
    }

    .a-infos {
      h4 {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  

  .tags {
    width: 100%;
    margin: 0.5rem 0 0.5rem 60px;

    @include tablet {
      margin-left: 0;
      overflow-x: auto;
    }

    .tag {
      padding: 5px 12.5px;
      border-radius: 50px;
      font-size: 12px;
      font-weight: 600;
      color: var(--grey);
      border: 1px solid var(--grey);
      background-color: white;
      margin-right: 5px;
    }

    & .buy {
      color: white;
      background-color: var(--light-green);
      border: 1px solid var(--light-green);
    }

    & .sell {
      color: white;
      background-color: rgb(234, 69, 69);
      border: 1px solid rgb(234, 69, 69);
    }

    & .index {
      color: white;
      background-color: #292B3E;
      border: 1px solid #292B3E;
    }
  }

  .idea-locked {
    width: 100%;
    margin: 15px 0;
    padding: 22px 20px;
    border-radius: 8px;
    background: var(--bluish);

    .idea-locked-label{
      img {
        margin-right: 20px;
      }
    }
  }

  .idea-text {
    width: 100%;
    margin: 15px 0;

    h5 {
      font-size: 14px;
      font-weight: 400;
    }

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .link-prv {
    width: 100%;
    padding: 1rem 0;

    .Container {
      flex-direction: row !important;

    }

    .Title {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .Image {
      width: 20%;
      background-size: cover;
    }

    .Description {
      font-size: 12px;
    }

    .SiteDetails {
      font-size: 12px;
      margin-top: 5px;
    }

    iframe {
      margin: 0.5rem 0;
      width: 50%;
      height: 300px;
      border-radius: var(--br-inputs);
    }
  }

  .assets-r {
    width: 100%;
    height: 90px;
    margin: 0.5rem 0;

    .audio-display {
      background-color: var(--greyish-3);
      border-radius: 50px;
      width: 50%;
      padding: 5px 10px;

      & .model-audio {
        width: 100%;
        background-color: #f7f7f76a;

      }

      .audio-timer {
        padding: 10px 0;
      }

      .record-btns {
        //  margin: 0 0.5rem;
        height: 45px;
        width: 45px;

        button {
          background-color: unset;
          height: 45px;

          svg {
            height: 45px;
            width: 45px;
          }

        }
      }
    }

    .images-display {
      width: 50%;
      height: 100%;
      border-radius: 5px;


      .img-c {
        width: 33.4%;
        height: 100%;
        border-radius: 5px;
        margin: 0 0 0 5px;
        position: relative;
        cursor: pointer;

        .img-overlay {
          position: absolute;
          border-radius: 5px;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.652);
          z-index: 2;

          h5 {
            color: white;
            font-weight: 500;
            font-size: 22px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  }


  .awards {
    width: 100%;
    height: 50px;
    padding: 1rem;
    margin-bottom: 22px;
    background: #F7F7F7;
    border-radius: var(--br-inputs);

    .aw-pics {
      flex-grow: 1;
      gap: 10px;

      .aw-pic {
        gap: 4px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    .aw-count {
      font-weight: 500;
      font-size: 12px;
    }
  }

  .social-count {
    width: 100%;
    height: 22px;

    .liked {
      svg {
        path {
          fill: red;
        }
      }
    }


    span {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 0 10px;
      cursor: pointer;
      user-select: none;

      svg {
        margin-right: 5px;
      }
    }
  }
}

.btns {
  width: 100%;

  button {
    width: 35%;
    height: 50px;
    margin: 10px;
    font-weight: 400;
  }

  & .confirm {
    background-image: var(--green-gradient);
    color: white;
  }
}

.idea-preview {
  width: 257px;
  padding: 5px;
  background: transparent;

  .tags {
    width: 100%;
    margin: 0.5rem 0;

    .tag {
      padding: 2px 6px;
      border-radius: 50px;
      font-size: 10px;
      font-weight: 400;
      color: var(--grey);
      border: 1px solid var(--grey);
      background-color: white;
      margin-right: 5px;
    }

    & .buy {
      color: white;
      background-color: var(--light-green);
      border: 1px solid var(--light-green);
    }

    & .sell {
      color: white;
      background-color: rgb(234, 69, 69);
      border: 1px solid rgb(234, 69, 69);
    }

    & .index {
      color: white;
      background-color: #292B3E;
      border: 1px solid #292B3E;
    }
  }

  .idea-text {
    width: 100%;
    padding: .25rem 0;

    h5 {
      font-size: 12px;
      font-weight: 400;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      padding: 5px 0;
    }
  }

  .assets-r {
    width: 100%;
    height: 90px;
    margin: 0.5rem 0;

    //.audio-display {
    //  background-color: #F7F7F7;
    //  border-radius: 50px;
    //  width: 50%;
    //  height: 45px;
    //  padding: 0 4px 0 0;
    //  margin-right: 1rem;
    //
    //  .audio-c {
    //    width: 90%;
    //    height: 40px;
    //    background-color: #F7F7F7;
    //    border-radius: 50px;
    //    margin: 5px 0;
    //  }
    //
    //  .audio-speed {
    //    padding: 0 5px;
    //    font-weight: 700;
    //    font-size: 14px;
    //    cursor: pointer;
    //    user-select: none;
    //  }
    //
    //  & .model-audio {
    //    width: 100%;
    //    background-color: #f7f7f76a;
    //
    //  }
    //
    //  .audio-timer {
    //    padding: 10px 0;
    //  }
    //
    //  .record-btns {
    //    //  margin: 0 0.5rem;
    //    height: 45px;
    //    width: 45px;
    //
    //    button {
    //      background-color: unset;
    //      height: 45px;
    //
    //      svg {
    //        height: 45px;
    //        width: 45px;
    //      }
    //
    //    }
    //  }
    //}

    .images-display {
      width: 50%;
      height: 100%;
      border-radius: 5px;


      .img-c {
        width: 33.4%;
        height: 100%;
        border-radius: 5px;
        margin: 0 0 0 5px;
        position: relative;

        .img-overlay {
          position: absolute;
          border-radius: 5px;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.652);
          z-index: 2;

          h5 {
            color: white;
            font-weight: 500;
            font-size: 22px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }

    }
  }
}