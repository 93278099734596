.notification-dropdown{
    position: absolute;
    width: 420px;
    height: auto;
    background-color: var(--white);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    right: 0;
    top: 50px;
    padding: 22px 20px;
    gap: 20px;
    border-radius: 6px;
    .notification-header{
        font-weight: bold;
        font-size: 18px;
        width: 100%;
    }
    .notification-list{
        gap: 10px;
        width: 100%;
    }
    .more-notifications{
        position: relative;
        background-color: transparent;
        color: var(--blue);
        font-size: 14px;
    }
    .more-notifications:hover{
        text-decoration: underline;
    }
    .unread-notification-indicator{
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--venetian-red);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: white;
        top: 1px;
        right: 0;
    }
    
}