.reply-wrapper{
    justify-content: space-between;
    background: var(--greyish-3);
    width: 100%;
    border-radius: 5px;
    .reply-display{
        background: var(--greyish-3);
        display: flex;
        flex-direction: column;
        border-radius: 3px;
        width: 100%;
        .reply-text{
            font-size: 12px;
            color: var(--greyish-blue-2);
        }
        .reply-image{
        display: flex;
        gap: 5px;
        img{
            border-radius:  3px;
            object-fit: cover;
            width: 50px;
            height: 50px;
        }
        }
        .reply-Author{
        font-size: 10px;
        color: purple;
        margin-bottom: 3px;
        }
        .reply-post{
            color: var(--greyish-blue-2);
            font-size: 12px;
            font-style: italic;
            display: flex;
            align-items: center;
            svg{
                width: 10px;
                height: 10px;
                path{
                stroke: var(--greyish-blue-2);
                }
            }
        }
        .reply-audio{
            display: flex;
            width: 100%;
            color: var(--greyish-blue-2);
            font-size: 12px;
            align-items: center;
            svg{
                width: 15px;
                height: 15px;
            }
            p{
                margin: 0 3px;
            }
        }
    }
    button{
        background: transparent;
    }
}
.sender-reply{
    padding: 3px;
    background-color: white;
}
.reciever-reply{
padding: 3px;
background-color: var(--dark-green);
}