@import "/src/assets/styles/mixins";

.post-tags {
  width: 100%;
  gap: 4px;

  @include tablet {
    margin-left: 0;
    width: 100%;
    overflow-x: auto;
  }


}

.tag {
  padding: 0 8px;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #989FA6;
  background-color: #F7F7F7;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  white-space: nowrap;
  flex-shrink: 0;


  &.buy {
    color: #01A385;
    background-color: #D0F0EA;
  }

  &.sell {
    color: #B1454F;
    background-color: #FF9FA7;
  }

  &.index {
    color: #578DF5;
    background-color: #E9F3FD;
  }
}