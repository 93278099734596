
.follow-btn {
  color: white;
  background: var(--just-green);
  height: 35px;
  padding: 0 20px;
  width: 150px;
  border-radius: 50px;

  &.follow-btn-search {
    max-width: 100px;
    min-width: 100px;
    border-radius: 5px;
    padding: 0;
    background: var(--greyish-3);
    color: var(--greyish-blue-2);
    flex-shrink: 0;
  }

  &.follow-btn-notification {
    max-width: 100px;
    min-width: 100px;
    height: 32px;
    border-radius: 5px;
    color: var(--greyish-blue-2);
    background-color: var(--greyish-3);
    margin: 0;
    flex-shrink: 0;
  }
}

.unfollow-btn {
  width: 150px;

  &.unfollow-btn-search {
    flex: 1;
    max-width: 100px;
    min-width: 100px;
    border-radius: 5px;
    padding: 0;
    background: var(--greyish-3);
    color: var(--red);

    &:hover {
      color: var(--venetian-red);
    }
  }

  &.unfollow-btn-notification {
    min-width: 100px;
    width: 92px;
    height: 32px;
    border-radius: 5px;
    color: var(--just-green-2);
    background-color: var(--greyish-3);
    margin: 0;
    padding: 0;
  }
}