.setting-confirmation-wrapper{
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    border-radius: 6px;
    bottom: 0;
    .setting-confirmation{
        background: var(--white);
        width: 260px;
        border-radius: 6px;
        gap: 15px;
        padding: 25px;
    }
    .setting-confirmation-heading{
        font-weight:600;
        font-size: 14px;
    }
    p{
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        color: var(--greyish-blue-2);
        width: 160px;
    }
    .confirmation-actions{
        .confirm-action{
            background-color: #b00020;
            color: white;
            width: 150px;
            height: 35px;
            border-radius: 6px;
        }
        .cancel-action{
            background: transparent;
            font-size: 12px;
            font-weight: 600;
            margin-top: 13px;
        }
        .cancel-action:hover{
            text-decoration: underline;
        }
    }
}