.mention-text {
    font-weight: inherit;
    color: var(--just-blue-2);
    text-align: center;
    font-size: inherit;
    box-sizing: border-box;
    font-family: inherit;
  }

.formatted-link{
  color: var(--just-blue-2);
  &:hover{
    color: var(--just-blue-2);
    text-decoration: underline;
  }
  overflow-wrap: break-all;
  word-wrap: break-all;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-all;
}