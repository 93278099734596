@import "src/assets/styles/mixins";

.preview-name {
  font-size: 12px;
}

.author {
  width: 100%;
  gap: 8px;

  .a-infos {
    h4 {
      font-size: 14px;
      font-weight: 500;
    }

    .a-details {
      gap: 5px;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      h5 {
        font-size: 12px;
        color: var(--grey);
        font-weight: 400;
      }

      svg {
        width: 4px;
        height: 4px;

        circle {
          fill: var(--greyish-blue-2);
        }

        @include mobile {
          display: none;
        }
      }
    }
  }
}