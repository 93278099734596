.chatBubble-wrapper {
  margin: 5px;
  display: flex;

  .chat-options {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;

    button {
      width: 30px;
      height: 25px;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px 0 0;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    button:hover {
      svg {
        path {
          stroke: var(--greyish-blue-2);
        }
      }
    }
  }
}

.chatBubble-wrapper:hover {
  .chat-options {
    display: flex;

  }
}

.sender-wrapper {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.reciever-wrapper {
  align-self: flex-start;
}

.text-border {
  border: 1px solid var(--greyish-2);
}

.text-reciever {
  color: black;
}

.sender {
  position: relative;
  max-width: 280px;
  border-radius: 10px 10px 0 10px;
  background-color: #D0F0EA;
}

.reciever {
  position: relative;
  max-width: 280px;
  border-radius: 10px 10px 10px 0;
  background-color: var(--white);
  overflow: hidden;
}

.text {
  padding: 6px 13px;
  font-size: 12px;
  word-break: break-word;
}

.post {
  padding: 5px;
  border-radius: 10px 10px 10px 0;

}

.post-reciever, .audio-receiver {
  background-color: white;
  border: 1px solid var(--dark-green);
  border-radius: 10px 10px 10px 0;
}

.chatBubble-wrapper {
  .contact-avatar {
    margin: 5px;

    .chat-avatar {
      margin-right: 0;
    }
  }
}

.images-display {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 5px;
  gap: 5px;

  .img-c {
    width: 100px;
    height: 100%;
    border-radius: 5px;
    margin-right: 0;
    position: relative;
    cursor: pointer;

    .img-overlay {
      position: absolute;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.652);
      z-index: 2;

      h5 {
        color: white;
        font-weight: 500;
        font-size: 22px;
      }
    }

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.reply-display {

  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px;
  border-left: 3px solid purple;

  .reply-text {
    font-size: 12px;
    color: var(--greyish-blue-2);
  }

  .reply-image {
    display: flex;
    gap: 5px;

    img {
      border-radius: 3px;
      object-fit: cover;
      width: 50px;
      height: 50px;
    }
  }

  .reply-Author {
    font-size: 10px;
    color: purple;
    margin-bottom: 3px;
  }

  .reply-post {
    color: var(--greyish-blue-2);
    font-size: 12px;
    font-style: italic;
    display: flex;
    align-items: center;

    svg {
      width: 10px;
      height: 10px;

      path {
        stroke: var(--greyish-blue-2);
      }
    }
  }
}

.delete-popup {

  border-radius: 8px;

  .delete-modal {
    width: 320px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    padding: 32px;

    h4 {
      text-align: left;
      font-weight: bold;
      margin: 5px 0;
    }

    p {
      text-align: left;
      color: var(--greyish-blue-2);
      margin: 5px 0;
    }

    .delete-actions {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 10px 0;

      button {
        padding: 5px 10px;
        font-size: 14px;
        margin: 0 8px;
        border-radius: 10px;
      }

      .delete-action {
        background-color: red;
        color: white;
      }
    }
  }
}

.chat-timestamp {
  margin: 5px;
  font-size: 10px;
  color: var(--greyish-2);
}

.audio-wrapper {
  display: flex;
  align-items: center;
  padding: 5px;

  .audio-c {
    display: flex;
    width: 180px;
    height: 40px;
    border-radius: 50px;
    margin: 5px 0;
  }

  .audio-speed {
    width: 20px;
    margin: 0 5px;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
  }

  & .model-audio {
    width: 100%;
    background-color: #f7f7f76a;

  }

  .record-btns {
    //  margin: 0 0.5rem;
    height: 45px;
    width: 45px;

    button {
      background-color: unset;
      height: 45px;

      svg {
        height: 35px;
        width: 35px;
      }

    }
  }

}