.audio-recorder{
    padding: 5px;
    gap: 5px;
}
.record-control{
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}
.initial{
    background: var(--greyish-3);
    svg{
        height: 18px;
        width: 18px;
    }
}
.stop{
    svg{
        height: 18px;
        width: 18px;
    }
}
.delete{
    padding: 0;
}
.empty-wave{
    background-color: transparent;
    height: 30px;
    border-radius: 8px;
}
.playback-button{
    gap: 5px;
}
.audio-timer{
    color: var(--greyish-blue-2);
}