
.fs-in-right {
  gap: 10px;

  .fs-in-mv {
    padding: 5px 8px;
    color: var(--just-green);
    font-weight: 500;
    background-color: var(--change-green);
    border-radius: 4px;
    gap: 5px;

    svg {
      width: 10px;
      path {
        fill: var(--just-green);
      }
    }

    &.red {
      background-color: var(--change-red);
      color: var(--change-red-text);

      svg {
        path {
          fill: var(--change-red-text);
        }
      }
    }
  }
}