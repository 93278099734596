@import "src/assets/styles/mixins";

.give-award-popup {
  width: min(100%, 650px);
  padding: 20px;

  @include mobile {
    overflow-y: auto;
  }

  .gap-header {
    width: 100%;
  }

  .gap-content {
    margin-top: 40px;
    width: 100%;
    max-height: 50vh;
    gap: 30px;
    padding-bottom: 40px;
    overflow-y: auto;


    @include mobile {
      max-height: unset;
    }

    .gap-awards-grid {
      width: min(100%, 500px);
      gap: 30px;
      place-items: start;

      @include mobile {
        grid-template-columns: auto auto auto;
      }

      .gap-award-container {
        width: 100%;
        height: 100%;
        gap: 10px;
        select: none;
        border: 2px solid transparent;
        padding: 5px;

        &.gap-award-selected {
          border: 2px solid var(--greyish-blue-2);
          border-radius: 5px;
          background-color: var(--greyish-3);
        }

        img {
          width: 80%;
          aspect-ratio: 1;
        }
      }
    }
  }

  .gap-footer {
    width: min(100%, 600px);
    padding: 20px 0;
    border-top: 1px solid var(--greyish-blue-2);
    gap: 20px;

    @include mobile {
      flex-direction: column;
    }

    .gap-selected-award {
      width: 100%;
      gap: 10px;
      border-top: 1px solid var(--greyish-blue-2);
      padding: 20px;

      @include mobile {
        flex-direction: column;
      }
      .gap-selected-award-details {
        max-width: 400px;
        gap: 20px;
        padding: 0 20px;

        .gap-award-img {
          width: 50px;
          aspect-ratio: 1;
        }

        .gap-award-name-container {
          gap: 10px;

          .gap-award-name {
            color: var(--just-green);
          }
        }
      }

      .gap-spinner-container {
        width: 150px;
      }
    }

    .gap-available-coins {
      gap: 20px;

      @include mobile {
        flex-direction: column;
      }

      .gap-footer-row {
        gap: 10px;
        flex-wrap: wrap;

        .what-are-arya-coins-btn {
          color: var(--just-blue-2);
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}