.chat-avatar{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
}

.contact-avatar{
    position: relative;
    display: flex;
    align-items: center;
    &:hover{
        cursor: pointer;
    }
}