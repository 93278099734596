@import '../../../../assets/styles/mixins';

.image-fs-popup {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.783);
  padding: 10px 20px;
  position: relative;

  .top-bar {
    width: 100%;
    min-height: 50px;
    position: absolute;
    top: 20px;
    right: 20px;

    button {
      background-color: transparent;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    h4 {
      color: white;
      font-size: 18px;
    }
  }

  .image-ds {
    max-width: 1280px;
    max-height: 70%;
    flex: 1;
    flex-shrink: 0;

    .image-ds-media {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background-color: var(--just-blacker);
    }
  }

  .images-row {
    position: relative;
    height: min(10%, 200px);
    margin-top: 2.5%;
    overflow-x: auto;
    max-width: 95%;
    gap: 10px;

    @include tablet {
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .image-card {
      width: fit-content;
      height: 100%;
      cursor: pointer;
      border-radius: 4px;
      background-color: var(--just-blacker);
      position: relative;
      border: 2px solid transparent;

      .image-fs-video-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.652);
        border-radius: var(--br-inputs);

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 10%;
          min-width: 40px;
          height: auto;
        }
      }


      &.active-component {
        border: 2px solid var(--blue);
      }

      img, video {
        height: 100%;
        aspect-ratio: 1;
        background: none;
        object-fit: contain;
        border-radius: var(--br-inputs);
      }
    }
  }

}