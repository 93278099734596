.group-subscribe-btn {
  height: 30px;
  border-radius: 4px;
  background: var(--lighter-green);
  color: var(--just-green);
  font-size: 16px;
  padding: 0 14px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}